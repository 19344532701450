import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {
	AppIcons,
	DcOverlayBadgeModule,
	DcPopoverModule,
} from '@datachain/ui-sdk/components';
import { DcNavigateDirectiveModule } from '@datachain/ui-sdk/directives';
import { NgxColorsModule } from 'ngx-colors';

import { AppActionsIcons } from './app-actions.icons';
import { AppUiComponentIcons } from './app-components.icons';
import { AppDcIcons } from './app-dc.icons';
import { AppGdIcons } from './app-gd.icons';
import { AppHdIcons } from './app-hd.icons';
import { AppCardOutlineComponent } from './components/app-card-outline/app-card-outline.component';
import { AppDocHintsComponent } from './components/app-doc-hints/app-doc-hints.component';
import { AppMetadataComponent } from './components/app-metadata/app-metadata.component';
import { AppNavDocComponent } from './components/nav-doc/nav-doc.component';
import { DxtremeModule } from './dxtreme.module';
import { MaterialModule } from './material.module';

export const DcIconsInjectable = new InjectionToken('dc default namespace', {
	factory: (): AppIcons =>
		new AppDcIcons(inject(MatIconRegistry), inject(DomSanitizer)),
});

export const GdIconsInjectable = new InjectionToken('Generics data namespace', {
	factory: (): AppIcons =>
		new AppGdIcons(inject(MatIconRegistry), inject(DomSanitizer)),
});

export const HdIconsInjectable = new InjectionToken('Handle data namespace', {
	factory: (): AppIcons =>
		new AppHdIcons(inject(MatIconRegistry), inject(DomSanitizer)),
});

export const ActionIconsInjectable = new InjectionToken(
	'Actions data namespace',
	{
		factory: (): AppIcons =>
			new AppActionsIcons(inject(MatIconRegistry), inject(DomSanitizer)),
	}
);

export const UiComponentIconsInjectable = new InjectionToken(
	'ADOBIS UI components data namespace',
	{
		factory: (): AppIcons =>
			new AppUiComponentIcons(inject(MatIconRegistry), inject(DomSanitizer)),
	}
);

const availableComponents = [
	AppMetadataComponent,
	AppNavDocComponent,
	AppCardOutlineComponent,
	AppDocHintsComponent,
];

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MaterialModule,
		DxtremeModule,
		FormsModule,
		NgxColorsModule,
		DcOverlayBadgeModule,
		DcPopoverModule,
		DcNavigateDirectiveModule,
	],
	providers: [],
	declarations: availableComponents,
	exports: availableComponents,
})
export class UiModule {}
