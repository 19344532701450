import { Component, Input } from '@angular/core';

import { FunctionalDocumentationKeys } from '../../../core/documentation/functional-doc-keys';
import { ComponentIcons } from '../../app-components.icons';

export enum DocHintKeys {
	ExpositionQueryParams = 'exposition-query-params',
	Unknown = '',
}

@Component({
	selector: 'app-doc-hint',
	templateUrl: './app-doc-hints.component.html',
	styleUrls: ['./app-doc-hints.component.scss'],
	inputs: ['docKey'],
})
export class AppDocHintsComponent {
	public readonly ComponentIcons = ComponentIcons;
	public readonly FunctionalDocumentationKeys = FunctionalDocumentationKeys;
	public readonly DocHintKeys = DocHintKeys;
	public docToggle = false;

	@Input()
	public docKey = DocHintKeys.Unknown;
}
