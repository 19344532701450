import { createReducer, on } from '@ngrx/store';

import {
	computeUrisForConfigSuccess,
	fetchAvailableAccessForSimulationSuccess,
	fetchAvailableColumnsForSim,
	fetchAvailableColumnsForSimFail,
	fetchAvailableColumnsForSimSuccess,
	fetchAvailableExpositionsListSuccess,
	fetchAvailableGroupsForSimulationSuccess,
	fetchAvailableUsersForSimulationSuccess,
	fetchExpositionEndpointMetadataSuccess,
	fetchExpositionGridData,
	fetchExpositionGridDataFailedWithWarning,
	fetchExpositionGridDataSuccess,
	fetchExpositionMetadataSuccess,
	fetchExpositionSuccess,
	fetchRawJsonData,
	fetchRawJsonDataSuccess,
	fetchRawJsonDataWarning,
	fetchRawXmlData,
	fetchRawXmlDataSuccess,
	fetchRawXmlDataWarning,
	getCurrentExpositionMetadataSuccess,
	resetAvailableData,
} from './actions';
import { SimulatorSliceState } from './simulator.state';

export const simulatorReducer = createReducer<SimulatorSliceState>(
	new SimulatorSliceState(),
	on(fetchAvailableExpositionsListSuccess, (state) => state.resetSimulation()),
	on(fetchExpositionSuccess, (state) => state.resetSimulation()),
	on(fetchAvailableUsersForSimulationSuccess, (state, action) =>
		state.setSimulationUsers(action.payload)
	),
	on(fetchAvailableGroupsForSimulationSuccess, (state, action) =>
		state.setSimulationGroups(action.payload)
	),
	on(fetchAvailableAccessForSimulationSuccess, (state, action) =>
		state.setSimulationAccess(action.payload)
	),
	on(fetchAvailableColumnsForSim, (state) => state.setLoadingColumns(true)),
	on(fetchAvailableColumnsForSimSuccess, (state, action) =>
		state.setSimulationColumns(action.payload).setLoadingColumns(false)
	),
	on(fetchAvailableColumnsForSimFail, (state) =>
		state.setLoadingColumns(false)
	),
	on(fetchExpositionGridData, (state) =>
		state.setSimulationGridData(null).setSimulationErrors(null)
	),
	on(fetchRawJsonData, fetchRawXmlData, (state) => {
		let updated = state.setSimulationErrors(null);
		if (state.simulationData) {
			updated = updated.setSimulationGridData(
				state.simulationData.setRawData(null)
			);
		}
		return updated;
	}),
	on(
		fetchExpositionGridDataSuccess,
		fetchRawJsonDataSuccess,
		fetchRawXmlDataSuccess,
		(state, action) => state.setSimulationGridData(action.payload)
	),
	on(
		fetchExpositionGridDataFailedWithWarning,
		fetchRawJsonDataWarning,
		fetchRawXmlDataWarning,
		(state, action) => state.setSimulationErrors(action.payload.error)
	),
	on(getCurrentExpositionMetadataSuccess, (state, action) =>
		state.setExpositionSimulationMetadata(action.payload)
	),
	on(computeUrisForConfigSuccess, (state, action) => {
		if (action.errors.length !== 0) {
			return state
				.setExpositionSimulationUris(action.payload)
				.setSimulationErrors({
					code: 400,
					msg: action.errors[0],
				});
		}
		return state
			.setExpositionSimulationUris(action.payload)
			.setSimulationErrors(null);
	}),
	on(resetAvailableData, (state) => state.setSimulationGridData(null)),
	on(
		fetchExpositionMetadataSuccess,
		fetchExpositionEndpointMetadataSuccess,
		(state) => state.resetSimulation()
	)
);
