<app-overlay-badge *ngIf="docKey !== DocHintKeys.Unknown"
									 [icon]="ComponentIcons.Help"
									 [isOpen]="docToggle"
									 (click)="docToggle = !docToggle"
									 [ngStyle]="{ '--bg-clr': '#FFF' }">
	<app-popover
		overlayContent
		title="accepted syntax"
		i18n-title="@@expositions.access.config.lines.title"
		[icon]="{ iconName: ComponentIcons.Help }"
		[ngStyle]="{
				'--popover-body-h': '15rem',
				'--popover-body-max-h': '15rem',
				'--popover-w': '30rem'
			}"
		(adbPopoverClose)="docToggle = !docToggle">
		<ng-container [ngSwitch]="docKey">
			<ng-container *ngSwitchCase="DocHintKeys.ExpositionQueryParams">
				<ng-template *ngTemplateOutlet="filtersHint"></ng-template>
			</ng-container>
		</ng-container>
	</app-popover>
</app-overlay-badge>

<ng-template #filtersHint>
	<div class="doc-hint__lines-overlay">
		<div class="doc-hint__lines-syntax">
			<span
				i18n="@@expositions.access.config.lines.simpleSyntax"
				class="doc-hint__lines-bold">
				simple syntax</span>:
			<span i18n="@@expositions.access.config.lines.syntaxFormat">format</span>
			<a appNavigate [navigateTo]="FunctionalDocumentationKeys.EXPOSITION_FILTER_SYNTAX" i18n="@@general.read-more"
				 class="doc-hint__lines-syntax--read-more">read-more</a>
		</div>
		<div class="doc-hint__lines-operator-description">
			<span i18n="@@expositions.access.config.lines.groupOperator"
						class="doc-hint__lines-operator">
				()
			</span>
			<span i18n="@@expositions.access.config.lines.groupOperator.description">description</span>
		</div>
		<div class="doc-hint__lines-operator-description">
			<span
				i18n="@@expositions.access.config.lines.orOperator"
				class="doc-hint__lines-operator">and</span>
			<span i18n="@@expositions.access.config.lines.orOperator.description">description</span>
		</div>
		<div class="doc-hint__lines-operator-description">
			<span i18n="@@expositions.access.config.lines.andOperator"
						class="doc-hint__lines-operator">
				and</span>
			<span i18n="@@expositions.access.config.lines.andOperator.description">
				description</span>
		</div>
		<div class="doc-hint__lines-operator-description">
			<div>
				<span
					i18n="@@expositions.access.config.lines.doubleQuotes"
					class="doc-hint__lines-operator">
					""
				</span>
				<span i18n="@@expositions.access.config.lines.or">or</span>
				<span
					i18n="@@expositions.access.config.lines.singleQuotes"
					class="doc-hint__lines-operator">''</span>
			</div>
			<span
				i18n="@@expositions.access.config.lines.quotesOperator.description">
					description</span>
		</div>
		<div class="doc-hint__lines-example">
			<span class="doc-hint__lines-bold"
						i18n="@@expositions.access.config.lines.examples">
				examples</span>
			<span class="doc-hint__lines-example-details"
						i18n="@@expositions.access.config.lines.example-details">
				example</span>
		</div>
	</div>
</ng-template>
