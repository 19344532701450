import { Injectable } from '@angular/core';
import {
	camelToSnake,
	UrlService,
	CachePolicy,
	CachePolicyEntity,
} from '@datachain/ui-sdk/common';
import { AppTagItemEntity, ColumnType } from '@datachain/ui-sdk/components';
import { Map } from 'immutable';
import { map, Observable } from 'rxjs';

import { ExpositionEndpointMetadataEntity } from '../../expositions/components/exposition-endpoint-metadata-config/exposition-endpoint-metadata.entity';
import { ExpositionMetadataEntity } from '../../expositions/components/exposition-internal-metadata-config/exposition-metadata.entity';
import { SimulationAccessEntity } from '../../expositions/components/exposition-simulator/simulation-access.entity';
import { SimulationBaseEntity } from '../../expositions/components/exposition-simulator/simulation-base.entity';
import { SimulationGroupEntity } from '../../expositions/components/exposition-simulator/simulation-group.entity';
import { SimulationUserEntity } from '../../expositions/components/exposition-simulator/simulation-user.entity';
import {
	AccessConfigEntity,
	AccessType,
} from '../../expositions/domain/exposition-access-config.entity';
import { ExpositionColumnConfigEntity } from '../../expositions/domain/exposition-column-config.entity';
import { ExpositionConsumerMappingEntity } from '../../expositions/domain/exposition-consumer-mapping.entity';
import { ExpositionConsumerEntity } from '../../expositions/domain/exposition-consumer.entity';
import {
	SimulationExecutionPayload,
	SimulationType,
	SimulationUrisConfigPayload,
} from '../../expositions/domain/simulation.types';
import { DcBaseRequester } from '../core/dc-base.requester';
import { IJobTracking } from '../core/models/job-tracking';
import { IMarketPlaceMembers } from '../core/models/marketplace-members.model';
import {
	IDcMetadataResponse,
	IMetadataTag,
} from '../core/models/metadata.model';
import { IExpositionPublicationAccessConfig } from './models/draft-publication.model';
import { IExpositionColumnConfig } from './models/exposition-column-config.model';
import { IExpositionCreate } from './models/exposition-create.model';
import { IExpositionResponse } from './models/exposition.model';
import {
	IConsumerForDatablockExposition,
	IEligibleForMigration,
	IMigratedExposition,
} from './models/migrated-exposition.model';
import { IPublicMetadata } from './models/public-metadata.model';
import { IPublicationHistoryDetails } from './models/publication-history.model';
import {
	ExpositionsAvailableSimulationColumn,
	ExpositionSimulationAccess,
	ExpositionSimulationGroup,
	ExpositionSimulationUser,
	ParsedSimulationExec,
	SimulatedExpositionUris,
	SimulationDataError,
	SimulationExecData,
	SimulationExecRawData,
	SimulationExpositionMetadata,
} from './models/simulator.models';

@Injectable({
	providedIn: 'root',
})
export class ExpositionRequester extends DcBaseRequester<IExpositionResponse> {
	protected resource;
	protected elementsListUrl;
	protected singleElementUrl;

	public constructor(private readonly urlService: UrlService) {
		super();
		this.resource = this.generateResourceUrl('EXPOSITIONS_GET_ALL');
		this.elementsListUrl = this.resource;
		this.singleElementUrl = this.resource;
	}

	public getAllExpositions(): Observable<ReadonlyArray<IExpositionResponse>> {
		const url = this.urlService.generateUrl(
			this.resource,
			{},
			{
				project: this.projectId,
			}
		);
		return this.get<ReadonlyArray<IExpositionResponse>>(url);
	}

	public createExposition(payload: {
		datablockId: number;
		metadata: ExpositionMetadataEntity;
		endpointMetadata: ExpositionEndpointMetadataEntity;
		columnsConfig: Array<ExpositionColumnConfigEntity>;
		accessConfig: AccessConfigEntity;
	}): Observable<IExpositionResponse> {
		const body = this.generateExpositionPayloadForSave(
			payload.datablockId,
			payload.metadata,
			payload.endpointMetadata,
			payload.columnsConfig,
			payload.accessConfig
		);
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_GET_ALL');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{},
			{
				project: this.projectId,
			}
		);
		return this.http.post<IExpositionResponse>(url, body);
	}

	public getExposition(id: number): Observable<IExpositionResponse> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_GET');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id,
			},
			{
				project: this.projectId,
			}
		);
		return this.get<IExpositionResponse>(url);
	}

	public getExpositionHistory(
		id: number
	): Observable<Array<IPublicationHistoryDetails>> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_HISTORY_LIST');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id,
			},
			{
				project: this.projectId,
			}
		);
		return this.get<Array<IPublicationHistoryDetails>>(url);
	}

	public getJobErrorMessage(
		expositionId: number,
		historyId: number
	): Observable<string> {
		const resourceUrl = this.generateResourceUrl(
			'EXPOSITIONS_HISTORY_ERROR_MSG'
		);
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
				historyId,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.get(url, {
			responseType: 'text',
		});
	}

	public getExpositionMetadata(id: number): Observable<IDcMetadataResponse> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_METADATA');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id,
			},
			{
				project: this.projectId,
			}
		);
		return this.get<IDcMetadataResponse>(url);
	}

	public updateExpositionMetadata(
		id: number,
		metadata: ExpositionMetadataEntity
	): Observable<IDcMetadataResponse> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_METADATA');
		const body: IDcMetadataResponse = {
			label: metadata.label,
			description: metadata.description,
			tags: this.generateMetadataTagPayload(metadata.tags.toArray()),
			actif: false,
		};
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.put<IDcMetadataResponse>(url, body);
	}

	public getExpositionEndpointMetadata(
		id: number
	): Observable<IPublicMetadata> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_PUBLIC_METADATA');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id,
			},
			{
				project: this.projectId,
			}
		);
		return this.get<IPublicMetadata>(url);
	}

	public updateExpositionEndpointMetadata(
		id: number,
		metadata: ExpositionEndpointMetadataEntity
	): Observable<IPublicMetadata> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_PUBLIC_METADATA');
		const body: IPublicMetadata = {
			title: metadata.title,
			details: metadata.details,
			// eslint-disable-next-line camelcase
			access_point: metadata.accessPoint,
			keywords: this.generateKeywordsPayload(metadata.keywords.toArray()),
		};
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.put<IPublicMetadata>(url, body);
	}

	public getExpositionColumns(
		id: number
	): Observable<Array<IExpositionColumnConfig>> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_PUBLIC_COLUMNS');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id,
			},
			{
				project: this.projectId,
			}
		);
		return this.get<Array<IExpositionColumnConfig>>(
			url,
			CachePolicyEntity.build({
				policy: CachePolicy.NetworkOnly,
				evictTimeout: 5 * 60,
				isCacheable: true,
			})
		);
	}

	public updateExpositionColumns(
		id: number,
		columns: Array<ExpositionColumnConfigEntity>
	): Observable<Array<IExpositionColumnConfig>> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_PUBLIC_COLUMNS');
		const body = this.generateColumnsConfigPayload(columns);
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.put<Array<IExpositionColumnConfig>>(url, body);
	}

	public getExpositionAccess(
		expositionId: number,
		accessId: string
	): Observable<IExpositionPublicationAccessConfig> {
		const resourceUrl = this.generateResourceUrl(
			'EXPOSITIONS_PUBLIC_ACCESS_BY_ID'
		);
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
				accessId,
			},
			{
				project: this.projectId,
			}
		);
		return this.get<IExpositionPublicationAccessConfig>(url);
	}

	public updateExpositionAccess(
		expositionId: number,
		access: AccessConfigEntity
	): Observable<IExpositionPublicationAccessConfig> {
		const resourceUrl = this.generateResourceUrl(
			'EXPOSITIONS_PUBLIC_ACCESS_BY_ID'
		);
		const body = this.generateAccessConfigPayload(access);
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
				accessId: access.id,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.put<IExpositionPublicationAccessConfig>(url, body);
	}

	public createExpositionAccess(
		expositionId: number,
		access: AccessConfigEntity
	): Observable<IExpositionPublicationAccessConfig> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_PUBLIC_ACCESS');
		const body = this.generateAccessConfigPayload(access);
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.post<IExpositionPublicationAccessConfig>(url, body);
	}

	public deleteExpositionAccess(
		expositionId: number,
		accessId: string
	): Observable<void> {
		const resourceUrl = this.generateResourceUrl(
			'EXPOSITIONS_PUBLIC_ACCESS_BY_ID'
		);
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
				accessId,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.delete<void>(url);
	}

	public publishExposition(
		clientId: string,
		expositionId: number
	): Observable<IJobTracking> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_PUBLISH');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
			},
			{
				project: this.projectId,
				clientId,
			}
		);
		return this.http.post<IJobTracking>(url, {});
	}

	public updateExpositionData(
		clientId: string,
		expositionId: number
	): Observable<IJobTracking> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_PUBLISH');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
			},
			{
				project: this.projectId,
				clientId,
			}
		);
		return this.http.put<IJobTracking>(url, {});
	}

	public clearExpositionData(expositionId: number): Observable<void> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_DATA_CLEAR');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.delete<void>(url);
	}

	public activateExposition(expositionId: number): Observable<void> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_ENABLE');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.put<void>(url, {});
	}

	public deactivateExposition(expositionId: number): Observable<void> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_DISABLE');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.put<void>(url, {});
	}

	public updateExpositionParams(
		clientId: string,
		expositionId: number,
		updateMetadata: boolean,
		updateColumns: boolean,
		updateAccess: boolean
	): Observable<IJobTracking> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_PARAMS');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
			},
			{
				project: this.projectId,
				clientId,
				updateMetadata,
				updateColumns,
				updateAccess,
			}
		);
		return this.http.put<IJobTracking>(url, {});
	}

	public deleteExposition(expositionId: number): Observable<void> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_DELETE');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.delete<void>(url);
	}

	public getMigratedExpositionsList(
		caching = {
			noCache: false,
		}
	): Observable<ReadonlyArray<IMigratedExposition>> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_MIGRATED_LIST');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{},
			{
				project: this.projectId,
			}
		);
		const cachePolicy = caching.noCache
			? CachePolicyEntity.build({
					policy: CachePolicy.NetworkOnly,
					evictTimeout: 0,
					isCacheable: false,
			  })
			: CachePolicyEntity.build({
					policy: CachePolicy.NetworkAndCache,
					evictTimeout: 60,
					isCacheable: true,
			  });

		return this.get<ReadonlyArray<IMigratedExposition>>(url, cachePolicy);
	}

	public getExpositionsForMigrationList(
		caching = {
			noCache: false,
		}
	): Observable<ReadonlyArray<IEligibleForMigration>> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_MIGRATION_LIST');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{},
			{
				project: this.projectId,
			}
		);
		const cachePolicy = caching.noCache
			? CachePolicyEntity.build({
					policy: CachePolicy.NetworkOnly,
					evictTimeout: 0,
					isCacheable: false,
			  })
			: CachePolicyEntity.build({
					policy: CachePolicy.NetworkAndCache,
					evictTimeout: 60,
					isCacheable: true,
			  });

		return this.get<Array<IEligibleForMigration>>(url, cachePolicy);
	}

	public getConsumersForExposedDatablocks(
		dbIds: Array<number>
	): Observable<IConsumerForDatablockExposition> {
		const resourceUrl = this.generateResourceUrl(
			'EXPOSITIONS_MIGRATION_LIST_MEMBERS'
		);
		const url = this.urlService.generateUrl(
			resourceUrl,
			{},
			{
				selectedDbs: dbIds,
				project: this.projectId,
			}
		);

		return this.get<IConsumerForDatablockExposition>(
			url,
			CachePolicyEntity.build({
				policy: CachePolicy.NetworkOnly,
				evictTimeout: 0,
				isCacheable: false,
			})
		);
	}

	public mapConsumers(
		requestId: string,
		mapping: Map<string, ExpositionConsumerMappingEntity>
	): Observable<void> {
		const resourceUrl = this.generateResourceUrl(
			'EXPOSITIONS_MIGRATION_LIST_EXEC'
		);
		const payload: { [k: string]: { id: number; group: boolean } } = {};
		mapping.forEach((m) => {
			payload[m.id] = {
				id: m.targetConsumerId as number,
				group: m.targetConsumerIsGroup as boolean,
			};
		});
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				requestId,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.post<void>(url, payload);
	}

	public getAvailableSimulationsUsers(
		expositionId: number
	): Observable<ReadonlyArray<ExpositionSimulationUser>> {
		let url = this.generateResourceUrl('EXPOSITIONS_SIMULATION_CONFIG_USERS');
		url = this.urlService.generateUrl(
			url,
			{
				expositionId,
			},
			{
				project: this.projectId,
			}
		);
		return this.get<ReadonlyArray<ExpositionSimulationUser>>(
			url,
			CachePolicyEntity.build({
				policy: CachePolicy.CacheOnly,
				evictTimeout: 60,
				isCacheable: true,
			})
		);
	}

	public getAvailableSimulationsColumns(
		expositionId: number,
		payload:
			| SimulationUserEntity
			| Array<SimulationGroupEntity>
			| Array<SimulationAccessEntity>
	): Observable<ReadonlyArray<ExpositionsAvailableSimulationColumn>> {
		let url = this.generateResourceUrl('EXPOSITIONS_SIMULATION_COLUMNS');
		url = this.urlService.generateUrl(
			url,
			{
				expositionId,
			},
			{
				project: this.projectId,
			}
		);
		let body = {};
		if (Array.isArray(payload)) {
			const first = payload[0] as SimulationBaseEntity;
			if (first.getType() === 'Groups') {
				body = {
					groups: payload.map<number>((it) => it.id as number),
				};
			} else {
				body = {
					access: payload.map<string>((it) => it.id as string),
				};
			}
		} else {
			body = {
				[camelToSnake('userId')]: payload.id,
			};
		}

		return this.http.post<ReadonlyArray<ExpositionsAvailableSimulationColumn>>(
			url,
			body
		);
	}

	public fetchData(
		expositionId: number,
		options: SimulationExecutionPayload
	): Observable<ParsedSimulationExec> {
		let dataUrl = this.generateResourceUrl('EXPOSITIONS_SIMULATION_EXEC');
		dataUrl = this.urlService.generateUrl(
			dataUrl,
			{
				expositionId,
			},
			{
				project: this.projectId,
			}
		);
		let bodyConfig: Record<string, any> = {
			config: {
				[camelToSnake('selectedColumns')]: options.selectedCols.map(
					(it) => it.alias
				),
				[camelToSnake('orderBy')]: options.sortCols.map((it) => ({
					column: it.alias,
					order: it.sortOrder === 'asc' ? 'asc' : 'desc',
				})),
				count: true,
				filter: options.filter,
				page: options.nbOfPages,
				[camelToSnake('hitsPerPage')]: options.hitsPerPage,
			},
		};
		if (Array.isArray(options.accessConfig)) {
			const first = options.accessConfig[0] as SimulationBaseEntity;
			if (first.getType() === 'Groups') {
				bodyConfig = {
					...bodyConfig,
					groups: options.accessConfig.map<number>((it) => it.id as number),
				};
			} else {
				bodyConfig = {
					...bodyConfig,
					access: options.accessConfig.map<string>((it) => it.id as string),
				};
			}
		} else {
			bodyConfig = {
				...bodyConfig,
				[camelToSnake('userId')]: options.accessConfig.id,
			};
		}

		return this.http
			.post<SimulationExecData | SimulationDataError>(dataUrl, bodyConfig)
			.pipe(
				map((data) => {
					if ('errorResponse' in data || 'errorReponse' in data) {
						return {
							data: [],
							count: 0,
							rawData: [],
							errorPayload: {
								code: data.httpStatus,
								msg:
									data.errorReponse !== undefined
										? data.errorReponse
										: data.errorResponse !== undefined
										? data.errorResponse
										: '',
							},
						};
					}
					const execData = data as SimulationExecData;
					return {
						data: execData ? JSON.parse(execData.data as string) : [],
						count: execData?.count ?? 0,
						rawData: [],
						errorPayload: undefined,
					};
				})
			);
	}

	public getRawJsonData(
		expositionId: number,
		simulationType: SimulationType,
		options: SimulationExecutionPayload
	): Observable<ParsedSimulationExec> {
		let rawDataUrl = this.generateResourceUrl(
			'EXPOSITIONS_SIMULATION_EXEC_RAW'
		);
		rawDataUrl = this.urlService.generateUrl(
			rawDataUrl,
			{
				expositionId,
			},
			{
				project: this.projectId,
			}
		);
		let bodyConfig: Record<string, any> = {
			type: simulationType === 'odata' ? 'ODATA_JSON' : 'REST',
			config: {
				[camelToSnake('selectedColumns')]: options.selectedCols.map(
					(it) => it.alias
				),
				[camelToSnake('orderBy')]: options.sortCols.map((it) => ({
					column: it.alias,
					order: it.sortOrder === 'asc' ? 'asc' : 'desc',
				})),
				count: true,
				filter: options.filter,
				page: options.nbOfPages,
				[camelToSnake('hitsPerPage')]: options.hitsPerPage,
			},
		};
		if (Array.isArray(options.accessConfig)) {
			const first = options.accessConfig[0] as SimulationBaseEntity;
			if (first.getType() === 'Groups') {
				bodyConfig = {
					...bodyConfig,
					groups: options.accessConfig.map<number>((it) => it.id as number),
				};
			} else {
				bodyConfig = {
					...bodyConfig,
					access: options.accessConfig.map<string>((it) => it.id as string),
				};
			}
		} else {
			bodyConfig = {
				...bodyConfig,
				[camelToSnake('userId')]: options.accessConfig.id,
			};
		}

		return this.http
			.post<SimulationExecRawData | SimulationDataError>(rawDataUrl, bodyConfig)
			.pipe(
				map((data) => {
					if (
						typeof data !== 'string' &&
						('errorResponse' in data || 'errorReponse' in data)
					) {
						return {
							data: [],
							count: 0,
							rawData: '',
							errorPayload: {
								code: data.httpStatus,
								msg:
									data.errorReponse !== undefined
										? data.errorReponse
										: data.errorResponse !== undefined
										? data.errorResponse
										: '',
							},
						};
					}
					return {
						data: [],
						count: 0,
						rawData: data as SimulationExecRawData,
						errorPayload: undefined,
					};
				})
			);
	}

	public getRawXmlData(
		expositionId: number,
		options: SimulationExecutionPayload
	): Observable<Pick<ParsedSimulationExec, 'rawData'>> {
		let rawDataUrl = this.generateResourceUrl(
			'EXPOSITIONS_SIMULATION_EXEC_RAW'
		);
		rawDataUrl = this.urlService.generateUrl(
			rawDataUrl,
			{
				expositionId,
			},
			{
				project: this.projectId,
			}
		);
		let bodyConfig: Record<string, any> = {
			type: 'ODATA_XML',
			config: {
				[camelToSnake('selectedColumns')]: options.selectedCols.map(
					(it) => it.alias
				),
				[camelToSnake('orderBy')]: options.sortCols.map((it) => ({
					column: it.alias,
					order: it.sortOrder === 'asc' ? 'asc' : 'desc',
				})),
				count: true,
				filter: options.filter,
				page: options.nbOfPages,
				[camelToSnake('hitsPerPage')]: options.hitsPerPage,
			},
		};
		if (Array.isArray(options.accessConfig)) {
			const first = options.accessConfig[0] as SimulationBaseEntity;
			if (first.getType() === 'Groups') {
				bodyConfig = {
					...bodyConfig,
					groups: options.accessConfig.map<number>((it) => it.id as number),
				};
			} else {
				bodyConfig = {
					...bodyConfig,
					access: options.accessConfig.map<string>((it) => it.id as string),
				};
			}
		} else {
			bodyConfig = {
				...bodyConfig,
				[camelToSnake('userId')]: options.accessConfig.id,
			};
		}

		return this.http
			.post(rawDataUrl, bodyConfig, {
				responseType: 'text',
			})
			.pipe(
				map((rawData) => ({
					rawData: rawData ? rawData : '',
				}))
			);
	}

	public getCurrentExpositionSimMetadata(
		expositionId: number,
		useCache = true
	): Observable<SimulationExpositionMetadata> {
		let url = this.generateResourceUrl('EXPOSITIONS_SIMULATION_CONFIG');
		url = this.urlService.generateUrl(
			url,
			{
				expositionId,
			},
			{
				project: this.projectId,
			}
		);
		const cachePolicy = useCache
			? CachePolicyEntity.build({
					policy: CachePolicy.CacheOnly,
					evictTimeout: 60,
					isCacheable: true,
			  })
			: CachePolicyEntity.build({
					policy: CachePolicy.NetworkAndCache,
					evictTimeout: 60,
					isCacheable: true,
			  });
		return this.get<SimulationExpositionMetadata>(url, cachePolicy);
	}

	public computeExpositionUris(
		expositionId: number,
		options: SimulationUrisConfigPayload
	): Observable<SimulatedExpositionUris> {
		let url = this.generateResourceUrl('EXPOSITIONS_SIMULATION_URIS');
		url = this.urlService.generateUrl(
			url,
			{
				expositionId,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.post<SimulatedExpositionUris>(url, {
			[camelToSnake('selectedColumns')]: options.selectedCols.map(
				(it) => it.alias
			),
			[camelToSnake('orderBy')]: options.sortCols.map((it) => ({
				column: it.alias,
				order: it.sortOrder === 'asc' ? 'asc' : 'desc',
			})),
			count: true,
			filter: options.filter,
			page: options.nbOfPages,
			[camelToSnake('hitsPerPage')]: options.hitsPerPage,
		});
	}

	public getAvailableSimulationsGroups(
		expositionId: number
	): Observable<ReadonlyArray<ExpositionSimulationGroup>> {
		let url = this.generateResourceUrl('EXPOSITIONS_SIMULATION_CONFIG_GROUPS');
		url = this.urlService.generateUrl(
			url,
			{
				expositionId,
			},
			{
				project: this.projectId,
			}
		);
		return this.get<ReadonlyArray<ExpositionSimulationGroup>>(
			url,
			CachePolicyEntity.build({
				policy: CachePolicy.CacheOnly,
				evictTimeout: 60,
				isCacheable: true,
			})
		);
	}

	public getAvailableSimulationsAccess(
		expositionId: number
	): Observable<ReadonlyArray<ExpositionSimulationAccess>> {
		let url = this.generateResourceUrl('EXPOSITIONS_SIMULATION_CONFIG_ACCESS');
		url = this.urlService.generateUrl(
			url,
			{
				expositionId,
			},
			{
				project: this.projectId,
			}
		);
		return this.get<ReadonlyArray<ExpositionSimulationAccess>>(
			url,
			CachePolicyEntity.build({
				policy: CachePolicy.CacheOnly,
				evictTimeout: 60,
				isCacheable: true,
			})
		);
	}

	private constructSimulationType(
		type: SimulationType
	): 'REST' | 'ODATA_XML' | 'ODATA_JSON' {
		if (type === 'rest') {
			return 'REST';
		}
		if (type === 'odata') {
			return 'ODATA_JSON';
		}
		return 'ODATA_XML';
	}

	private generateExpositionPayloadForSave(
		datablockId: number,
		metadata: ExpositionMetadataEntity,
		endpointMetadata: ExpositionEndpointMetadataEntity,
		columns: Array<ExpositionColumnConfigEntity>,
		access: AccessConfigEntity
	): IExpositionCreate {
		const payload: IExpositionCreate = {
			// eslint-disable-next-line camelcase
			datablock_id: datablockId,
			metadata: {
				label: metadata.label,
				description: metadata.description,
				tags: this.generateMetadataTagPayload(metadata.tags.toArray()),
				actif: false,
			},
			title: endpointMetadata.title,
			details: endpointMetadata.details,
			keywords: this.generateKeywordsPayload(
				endpointMetadata.keywords.toArray()
			),
			// eslint-disable-next-line camelcase
			access_point: endpointMetadata.accessPoint,
			access: [this.generateAccessConfigPayload(access)],
			columns: this.generateColumnsConfigPayload(columns),
		};
		return payload;
	}

	private generateAccessConfigPayload(
		access: AccessConfigEntity
	): IExpositionPublicationAccessConfig {
		const columns = access.columnsConfig.elements.toArray().map((c) => c[1]);
		const consumers = access.consumers.elements.toArray().map((c) => c[1]);
		return {
			id: access.id,
			type: access.type === AccessType.Open ? 'OPEN' : 'LIMITED',
			label: access.label,
			description: access.description,
			active: access.isActive,
			filter: access.filter,
			members: this.generateMembersPayload(consumers),
			columns: this.generateColumnsConfigPayload(columns),
		};
	}

	private generateColumnsConfigPayload(
		columns: Array<ExpositionColumnConfigEntity>
	): Array<IExpositionColumnConfig> {
		return columns
			.filter((c) => c.isActive)
			.map((c) => {
				const column: IExpositionColumnConfig = {
					uuid: c.id,
					label: c.label,
					alias: c.alias,
					description: c.description,
					type: this.getColumnType(c.columnType),
					list: c.isList,
					active: c.isActive,
					id: c.isPrimaryKey,
					filter: c.isFiltered,
					// eslint-disable-next-line camelcase
					case_insensitive: c.isCaseSensitive,
					hash: c.isHashed,
					hidden: c.isHidden,
					status: [],
				};
				return column;
			});
	}

	private generateMetadataTagPayload(
		tags: Array<AppTagItemEntity>
	): Array<IMetadataTag> {
		return tags.map((t) => ({
			id: t.id !== -1 ? t.id : undefined,
			code: t.label,
			color: t.color,
			position: t.position,
		}));
	}

	private generateKeywordsPayload(
		keywords: Array<AppTagItemEntity>
	): Array<string> {
		return keywords.map((t) => t.label);
	}

	private generateMembersPayload(
		consumers: Array<ExpositionConsumerEntity>
	): IMarketPlaceMembers {
		return {
			users: consumers
				.filter((c) => !c.isGroup)
				.map((c) => ({
					id: c.id,
					name: c.name,
				})),
			groups: consumers
				.filter((c) => c.isGroup)
				.map((c) => ({
					id: c.id,
					name: c.name,
				})),
		};
	}

	private getColumnType(type: ColumnType): string {
		switch (type) {
			case ColumnType.Str:
				return 'string';
			case ColumnType.File:
				return 'file';
			case ColumnType.Double:
				return 'double';
			case ColumnType.Dec:
				return 'decimal';
			case ColumnType.Date:
				return 'date';
			case ColumnType.BigInt:
				return 'big_integer';
			case ColumnType.Bin:
				return 'binary';
			case ColumnType.Words:
				return 'words';
			case ColumnType.Int:
				return 'integer';
			case ColumnType.Bool:
				return 'boolean';
			case ColumnType.Geo:
				return 'geometry';
			default:
				return '';
		}
	}
}
