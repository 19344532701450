<ng-container *ngIf="vo$ | async; let vo">
	<app-page>
		<app-page-header>
			<app-breadcrumb [paths]="paths"></app-breadcrumb>
		</app-page-header>

		<app-page-content>
			<div class="exposition-simulator">
				<div class="exposition-simulator_metadata-columns">
					<app-card-outline id="{{ cmpId }}-metadata">
						<div card-outline-content class="exposition-simulator_metadata">
							<h3>{{ vo.simulationMetadata.endPointLabel }}</h3>

							<div class="exposition-simulator_metadata__timeline">
								<app-chip>
									<mat-icon [svgIcon]="DcIcons.Share" app-chip-icon></mat-icon>
									<span>
										{{
											vo.simulationMetadata.publishedOn | dayjsDate: 'DateTime'
										}}
									</span>
								</app-chip>
								<app-chip>
									<mat-icon [svgIcon]="DcIcons.Update" app-chip-icon></mat-icon>
									<span>
										{{
											vo.simulationMetadata.updatedOn | dayjsDate: 'DateTime'
										}}
									</span>
								</app-chip>
							</div>

							<span>{{ vo.simulationMetadata.details }}</span>

							<div class="exposition-simulator_metadata__keywords">
								<app-chip
									*ngFor="let k of vo.simulationMetadata.keywords">
									<span>{{ k }}</span>
								</app-chip>
							</div>

							<div class="exposition-simulator_metadata__endpoints">
								<app-overlay-badge
									[active]="true"
									[isOpen]="endPointsOverlay$.value.rest"
									value="REST"
									(overlayDismiss)="closeEndpointMetadataPopover()"
									(mouseenter)="openEndpointMetadataPopover($event, 'REST')"
									(mouseleave)="closeEndpointMetadataPopover()">
									<app-popover
										class="endpoint"
										id="rest-popover-endpoint-metadata"
										overlayContent
										title="REST"
										(mouseenter)="openEndpointMetadataPopover($event, 'REST')"
										(mouseleave)="closeEndpointMetadataPopover()"
										(adbPopoverClose)="closeEndpointMetadataPopover()">
										<div class="url-popover">
											<span>{{ vo.simulationMetadata.urls.rest }}</span>
											<app-button
												id="{{ cmpId }}-copy-rest-endpoint-url-btn"
												[leftIcon]="DcIcons.CopyFile"
												[cdkCopyToClipboard]="vo.simulationMetadata.urls.rest"
												(cdkCopyToClipboardCopied)="
													openSnackBar()
												"></app-button>
										</div>
									</app-popover>
								</app-overlay-badge>

								<app-overlay-badge
									[active]="true"
									[isOpen]="endPointsOverlay$.value.odata"
									value="OData"
									(mouseenter)="openEndpointMetadataPopover($event, 'ODATA')"
									(mouseleave)="closeEndpointMetadataPopover()"
									(overlayDismiss)="closeEndpointMetadataPopover()">
									<app-popover
										class="endpoint"
										id="odata-popover-endpoint-metadata"
										overlayContent
										title="OData"
										(mouseenter)="openEndpointMetadataPopover($event, 'ODATA')"
										(mouseleave)="closeEndpointMetadataPopover()"
										(adbPopoverClose)="closeEndpointMetadataPopover()">
										<div class="url-popover">
											<span>{{ vo.simulationMetadata.urls.odata }}</span>
											<app-button
												id="{{ cmpId }}-copy-odata-endpoint-url-btn"
												[leftIcon]="DcIcons.CopyFile"
												[cdkCopyToClipboard]="vo.simulationMetadata.urls.odata"
												(cdkCopyToClipboardCopied)="
													openSnackBar()
												"></app-button>
										</div>
									</app-popover>
								</app-overlay-badge>
							</div>
						</div>
					</app-card-outline>

					<app-panel
						*ngIf="vo.availableSimCols.getElements().size !== 0"
						class="exposition-simulator_columns"
						[itemsCount]="vo.availableSimCols.getElements().size"
						[isAlwaysOpen]="true">
						<div panel-title class="app-panel__title">
							<mat-icon [svgIcon]="ComponentIcons.Column"></mat-icon>
							<span i18n="@@expositions.simulator.available-columns">available-columns</span>
						</div>
						<ng-container *ngTemplateOutlet="availableColumns"></ng-container>
					</app-panel>
				</div>

				<div class="exposition-simulator_type">
					<app-panel [isAlwaysOpen]="true">
						<span panel-title i18n="@@expositions.simulator.sim-type">Type de simulation</span>
						<app-toggle-buttons
							id="{{ cmpId }}-simulation-type"
							class="exposition-simulator_type-selection"
							[actions]="simulateForToggleOptions"></app-toggle-buttons>

						<div
							*ngIf="
								vo.simulationTargets.length !== 0 &&
								vo.simulationFor === 'Users'
							"
							class="selected-options">
							<app-chip
								*ngFor="let target of vo.simulationTargets"
								viewMode="default"
								(remove)="removeSelected(target)"
								[label]="target.label">
								<app-avatar
									[ngStyle]="{
										'--avatar-width': '1.5rem',
										'--avatar-bg-default': target.avatar.color
									}"
									app-chip-icon>
									{{ target.label[0] }}
								</app-avatar>
							</app-chip>
						</div>

						<div
							*ngIf="
								vo.simulationTargets.length !== 0 &&
								vo.simulationFor === 'Groups'
							"
							class="selected-options">
							<app-chip
								*ngFor="let target of vo.simulationTargets"
								viewMode="default"
								(remove)="removeSelected(target)"
								[label]="target.label">
								<app-avatar
									[ngStyle]="{
										'--avatar-width': '1.5rem',
										'--avatar-bg-default': target.avatar.color
									}"
									app-chip-icon>
									{{ target.label[0] }}
								</app-avatar>
							</app-chip>
						</div>

						<div
							*ngIf="
								vo.simulationTargets.length !== 0 &&
								vo.simulationFor === 'Access'
							"
							class="selected-options">
							<app-chip
								*ngFor="let target of vo.simulationTargets"
								(remove)="removeSelected(target)"
								viewMode="default"
								[label]="target.label"></app-chip>
						</div>

						<div class="exposition-simulator_type__selection">
							<app-multi-select-menu
								*ngIf="vo.simulationFor === 'none'"
								maxOptionLength="70"
								hasMultiSelection="false"
								[displayedOptions]="
									vo.simulationTypeOptions
								"></app-multi-select-menu>

							<app-multi-select-menu
								*ngIf="vo.simulationFor === 'Users'"
								maxOptionLength="70"
								[mode]="'avatar'"
								[hasMultiSelection]="false"
								label="user"
								i18n-label="@@expositions.simulator.user"
								[displayedOptions]="vo.simulationTypeOptions"
								(selectedIds)="updateSelection($event)"></app-multi-select-menu>

							<app-multi-select-menu
								*ngIf="vo.simulationFor === 'Groups'"
								maxOptionLength="70"
								[hasMultiSelection]="true"
								label="group"
								i18n-label="@@expositions.simulator.group"
								[displayedOptions]="vo.simulationTypeOptions"
								(selectedIds)="updateSelection($event)"></app-multi-select-menu>

							<app-multi-select-menu
								*ngIf="vo.simulationFor === 'Access'"
								maxOptionLength="70"
								[hasMultiSelection]="true"
								label="access"
								i18n-label="@@expositions.simulator.access"
								[displayedOptions]="vo.simulationTypeOptions"
								(selectedIds)="updateSelection($event)"></app-multi-select-menu>

							<app-button
								loaderSize="20"
								[isDisabled]="vo.simulationTargets.length === 0"
								[isInProgress]="vo.loadingColumns"
								id="exposition-simulation-type-btn"
								(click)="computeAllowedColumns()"
								i18n="@@expositions.simulator.simulate-action">
								simulate
							</app-button>
						</div>
					</app-panel>

					<app-panel
						*ngIf="vo.availableSimCols.getElements().size !== 0"
						class="exposition-simulator_query-params"
						[isAlwaysOpen]="true">
						<div panel-title class="app-panel__title">
							<mat-icon [svgIcon]="DcIcons.Filter"></mat-icon>
							<span i18n="@@expositions.simulator.query-params">
								query-params
							</span>
						</div>
						<div class="exposition-simulator_query-params-container">
							<div>
								<app-columns-configurator
									(handleRemove)="columnRemoved($event)"
									(handleColumnsOrderingChange)="columnOrderModified($event)"
									[elements]="vo.selectedColumns"
									[viewMode]="'dragDrop'"></app-columns-configurator>

								<div class="exposition-simulator__columns">
									<app-multi-select-menu
										maxOptionLength="70"
										[displayedOptions]="vo.columnOptions"
										[mode]="'type'"
										[hasMultiSelection]="true"
										(selectedIds)="selectedColumns($event)"
										label="query-params-columns"
										i18n-label="
											@@expositions.simulator.query-params.columns"></app-multi-select-menu>
									<app-button
										*ngIf="vo.selectedColumns.length !== 0"
										[leftIcon]="DcIcons.ResetSelection"
										(click)="removeAllSelectedColumns('columns')"></app-button>
								</div>
							</div>

							<app-doc-hint [docKey]="DocHintKeys.ExpositionQueryParams"></app-doc-hint>

							<div class="exposition-simulator__filter">
								<mat-form-field
									id="{{ cmpId + '-filters-text-area' }}"
									appearance="fill"
									class="exposition-simulator_query-params__filter">
									<mat-label i18n="@@expositions.simulator.filter">
										filters
									</mat-label>
									<textarea
										type="text"
										matInput
										[rows]="vo.simulationMetadata.details ? 10 : 4"
										[ngModel]="filterParamSubject.value"
										(ngModelChange)="triggerFilterParamChange($event)"
										placeholder="filters-placeholder"
										i18n-placeholder="
											@@expositions.simulator.filter.placeholder"></textarea>
								</mat-form-field>
								<app-button
									*ngIf="filterParamSubject.value"
									[leftIcon]="DcIcons.ResetSelection"
									(click)="removeFilter()"></app-button>
							</div>

							<div>
								<app-columns-configurator
									(handleRemove)="sortColumnRemoved($event)"
									(handleColumnsOrderingChange)="
										sortColumnOrderModified($event)
									"
									(handleSortOrderChange)="sortOrderModified($event)"
									[elements]="vo.selectedSortColumns"
									[viewMode]="'dragDrop'"
									[hasActiveSort]="true"></app-columns-configurator>

								<div class="exposition-simulator__sort-columns">
									<app-multi-select-menu
										maxOptionLength="70"
										[displayedOptions]="vo.sortColumnOptions"
										[mode]="'type'"
										[hasMultiSelection]="true"
										(selectedIds)="selectedSortColumns($event)"
										label="query-params-columns"
										i18n-label="
											@@expositions.simulator.query-params.sort-columns"></app-multi-select-menu>

									<app-button
										*ngIf="vo.selectedSortColumns.length !== 0"
										[leftIcon]="DcIcons.ResetSelection"
										(click)="removeAllSelectedColumns('sort')"></app-button>
								</div>
							</div>
						</div>

						<div class="exposition-simulator_query-params__hits-pages">
							<mat-form-field
								id="{{ cmpId + '-page-input' }}"
								appearance="fill">
								<mat-label i18n="@@expositions.simulator.nb-pages">
									nb-pages
								</mat-label>
								<input
									type="number"
									matInput
									min="1"
									max="99"
									placeholder="page-count-placeholder"
									[ngModel]="pageCountParamSubject.value"
									(ngModelChange)="triggerPageCountParamParamChange($event)"
									i18n-placeholder="
										@@expositions.simulator.nb-pages.placeholder" />
							</mat-form-field>

							<mat-form-field
								id="{{ cmpId + '-hits-per-page-input' }}"
								appearance="fill">
								<mat-label i18n="@@expositions.simulator.hits-per-page">
									hits-per-pages
								</mat-label>
								<input
									type="number"
									matInput
									min="1"
									placeholder="hits-per-page-placeholder"
									[ngModel]="hitsPerPageParamSubject.value"
									(ngModelChange)="triggerHitsPerPageParamChange($event)"
									i18n-placeholder="
										@@expositions.simulator.hits-per-page.placeholder" />
							</mat-form-field>
						</div>
					</app-panel>
				</div>

				<app-panel
					*ngIf="vo.availableSimCols.getElements().size !== 0"
					class="exposition-simulator_url"
					[class.exposition-simulator_url--no-data]="
						vo.simulationData === null && vo.simulationError === null
					"
					[isAlwaysOpen]="true">
					<div panel-title class="app-panel__title exposition-https">
						<mat-icon [svgIcon]="DcIcons.URLs"></mat-icon>
						<span i18n="@@expositions.simulator.url">Url</span>
					</div>
					<div class="exposition-simulator_url__query-type">
						<app-toggle-buttons
							id="query-type"
							[actions]="queryType"></app-toggle-buttons>

						<app-button
							loaderSize="20"
							[isDisabled]="vo.simulationTargets.length === 0"
							[isInProgress]="vo.loadingColumns"
							class="exposition-simulator_type__simulate-btn"
							id="exposition-simulation-test-btn"
							(click)="simulate()"
							i18n="@@expositions.simulator.test-action">
							test
						</app-button>
					</div>

					<div
						*ngIf="vo.queryType === 'rest'"
						class="exposition-simulator_url__uris">
						<app-button
							*ngIf="vo.simulationUris.rest.countUrl"
							id="{{ cmpId }}-copy-count-url-btn"
							[leftIcon]="DcIcons.CopyFile"
							[cdkCopyToClipboard]="vo.simulationUris.rest.countUrl"
							(cdkCopyToClipboardCopied)="openSnackBar()"></app-button>
						<p>{{ vo.simulationUris.rest.countUrl | truncate: 200:2:'end' }}</p>

						<app-button
							*ngIf="vo.simulationUris.rest.dataUrl"
							id="{{ cmpId }}-copy-data-url-btn"
							[leftIcon]="DcIcons.CopyFile"
							[cdkCopyToClipboard]="vo.simulationUris.rest.dataUrl"
							(cdkCopyToClipboardCopied)="openSnackBar()"></app-button>
						<p>{{ vo.simulationUris.rest.dataUrl | truncate: 200:2:'end' }}</p>
					</div>

					<div
						*ngIf="vo.queryType === 'odata'"
						class="exposition-simulator_url__uris">
						<app-button
							*ngIf="vo.simulationUris.odata.jsonUrl"
							id="{{ cmpId }}-copy-odata-json-btn"
							[leftIcon]="DcIcons.CopyFile"
							[cdkCopyToClipboard]="vo.simulationUris.odata.jsonUrl"
							(cdkCopyToClipboardCopied)="openSnackBar()"></app-button>
						<p class="max-lines" appMaxLinesToggle [maxLines]="2">
							{{ vo.simulationUris.odata.jsonUrl | truncate: 200:2:'end' }}
						</p>

						<app-button
							*ngIf="vo.simulationUris.odata.xmlUrl"
							id="{{ cmpId }}-copy-odata-xml-btn"
							[leftIcon]="DcIcons.CopyFile"
							[cdkCopyToClipboard]="vo.simulationUris.odata.xmlUrl"
							(cdkCopyToClipboardCopied)="openSnackBar()"></app-button>
						<p class="max-lines" appMaxLinesToggle [maxLines]="2">
							{{ vo.simulationUris.odata.xmlUrl | truncate: 200:2:'end' }}
						</p>
					</div>
				</app-panel>

				<div
					*ngIf="vo.simulationError !== null || vo.simulationData !== null"
					class="exposition-simulator_result-summary"
					[class.exposition-simulator_result-summary--no-data]="
						vo.simulationData === null
					">
					<h1 i18n="@@expositions.simulator.results-summary">results</h1>
					<div class="">
						<span i18n="@@expositions.simulator.results-total-hits">total</span>
						: <span id="{{cmpId}}-total-count-result">{{ vo.simulationData?.count ?? 0 }}</span>
					</div>
					<div *ngIf="vo.simulationError !== null" class="simulation-error">
						<mat-icon [svgIcon]="DcIcons.Warning"></mat-icon>
						<span class="simulation-error__text">
							{{ vo.simulationError.msg }}
						</span>
					</div>
				</div>

				<app-card-outline
					*ngIf="vo.simulationData !== null"
					id="simulation-result"
					class="exposition-simulator_data">
					<div card-outline-content>
						<app-button
							id="{{ cmpId }}-copy-btn"
							*ngIf="vo.isCopyDataEnabled"
							[leftIcon]="DcIcons.CopyFile"
							[cdkCopyToClipboard]="vo.simulationData.rawData | json"
							(cdkCopyToClipboardCopied)="openSnackBar()"></app-button>

						<!--		REST Tabs				-->
						<mat-tab-group
							*ngIf="vo.queryType === 'rest'"
							[class.isCopyDataEnabled]="vo.isCopyDataEnabled"
							preserveContent="true"
							(selectedIndexChange)="handleDataTabsIdxChn($event, 'rest')">
							<mat-tab *ngFor="let tab of restResultTabs" [label]="tab.label">
								<dx-data-grid
									*ngIf="tab.index === RestDataTabs.Grid"
									id="rest-json-grid-result"
									[dataSource]="vo.simulationData.data"
									[columns]="vo.simulationData.gridData.columnsToRender"
									[hoverStateEnabled]="true"
									[allowColumnResizing]="true"
									[columnAutoWidth]="true"
									[showBorders]="true">
									<dxo-header-filter [visible]="true"></dxo-header-filter>
									<dxo-filter-row [visible]="true"></dxo-filter-row>

									<dxi-column
										*ngFor="
											let col of vo.simulationData.gridData.columnsToRender
										"
										[dataField]="col"
										[caption]="col"
										[dataType]="vo.simulationData.gridData.columnsDataTypes.get(
													col
												).dxDataType"
										headerCellTemplate="headerTmpl"></dxi-column>

									<div
										class="exposition-simulator_data__col-type"
										*dxTemplate="let data of 'headerTmpl'">
										<i>{{ data.column.caption }}</i>
										<app-column-type
											[type]="
												vo.simulationData.gridData.columnsDataTypes.get(
													data.column.caption
												).type
											"
											[isList]="
												vo.simulationData.gridData.columnsDataTypes.get(
													data.column.caption
												).isList
											"></app-column-type>
									</div>
								</dx-data-grid>

								<app-viewer id="raw-rest-json"
									*ngIf="
										tab.index === RestDataTabs.RawJSON &&
										vo.simulationData.rawData
									"
									[value]="vo.simulationData.rawData"
									mode="json"></app-viewer>
							</mat-tab>
						</mat-tab-group>

						<!--		OData Tabs				-->
						<mat-tab-group
							*ngIf="vo.queryType === 'odata'"
							[class.isCopyDataEnabled]="vo.isCopyDataEnabled"
							(selectedIndexChange)="handleDataTabsIdxChn($event, 'odata')">
							<mat-tab *ngFor="let tab of odataResultTabs" [label]="tab.label">
								<dx-data-grid
									*ngIf="tab.index === ODataTabs.Grid"
									id="odata-json-grid-result"
									[dataSource]="vo.simulationData.data"
									[columns]="vo.simulationData.gridData.columnsToRender"
									[hoverStateEnabled]="true"
									[allowColumnResizing]="true"
									[columnAutoWidth]="true"
									[showBorders]="true">
									<dxo-header-filter [visible]="true"></dxo-header-filter>
									<dxo-filter-row [visible]="true"></dxo-filter-row>

									<dxi-column
										*ngFor="
											let col of vo.simulationData.gridData.columnsToRender
										"
										[dataField]="col"
										[caption]="col"
										[dataType]="vo.simulationData.gridData.columnsDataTypes.get(
													col
												).dxDataType"
										headerCellTemplate="headerTmpl"></dxi-column>

									<div
										class="exposition-simulator_data__col-type"
										*dxTemplate="let data of 'headerTmpl'">
										<i>{{ data.column.caption }}</i>
										<app-column-type
											[type]="
												vo.simulationData.gridData.columnsDataTypes.get(
													data.column.caption
												).type
											"
											[isList]="
												vo.simulationData.gridData.columnsDataTypes.get(
													data.column.caption
												).isList
											"></app-column-type>
									</div>
								</dx-data-grid>

								<app-viewer id="raw-odata-json"
									*ngIf="
										tab.index === ODataTabs.RawJSON && vo.simulationData.rawData
									"
									[value]="vo.simulationData.rawData"
									mode="json"></app-viewer>

								<app-viewer id="raw-odata-xml"
									*ngIf="
										tab.index === ODataTabs.RawXML && vo.simulationData.rawData
									"
									[value]="vo.simulationData.rawData"
									mode="xml"></app-viewer>
							</mat-tab>
						</mat-tab-group>
					</div>
				</app-card-outline>
			</div>
		</app-page-content>
	</app-page>

	<ng-template #availableColumns>
		<dx-data-grid
			keyExpr="id"
			[elementAttr]="{
				id: 'simulator-columns',
				class: 'app-exposition-simulator'
			}"
			[dataSource]="vo.availableSimCols.elements | toArray"
			[hoverStateEnabled]="true"
			[showColumnHeaders]="true"
			[allowColumnResizing]="false"
			[columnAutoWidth]="false"
			[showRowLines]="false"
			[showColumnLines]="false"
			[rowAlternationEnabled]="true"
			[showBorders]="false">
			<dxo-header-filter [visible]="true"></dxo-header-filter>

			<dxo-scrolling mode="virtual"></dxo-scrolling>

			<dxo-load-panel
				shadingColor="rgba(0,0,0,0.4)"
				indicatorSrc="/src/img/loader-duotone.svg"
				[height]="100"
				[width]="100"
				[enabled]="true"
				[showIndicator]="true"
				[showPane]="true"
				[shading]="true"></dxo-load-panel>

			<dxo-filter-row [visible]="true"></dxo-filter-row>

			<!-- Pagination -->
			<dxo-paging [pageSize]="10"></dxo-paging>
			<dxo-pager [visible]="false"></dxo-pager>

			<ng-container
				*ngFor="let col of vo.availableSimCols.availableColumns | toArray">
				<dxi-column
					*ngIf="col.field === 'exposedLabel'"
					[dataField]="col.field"
					[caption]="col.alias"
					[groupIndex]="col.groupIdx"
					[fixed]="false"
					[allowResizing]="true"
					[allowHiding]="true"
					[allowGrouping]="true"
					[visible]="col.visible"
					[allowSearch]="col.isSearchable"
					[allowFiltering]="col.isFilterable"
					[allowSorting]="true"
					[format]="col.format"
					[alignment]="'left'"
					[visibleIndex]="col.visibleIndex"
					[allowHeaderFiltering]="col.hasHeaderFilter"
					[width]="200"
					cellTemplate="labelTemplate"></dxi-column>

				<dxi-column
					*ngIf="col.field === 'type'"
					[dataField]="col.field"
					[caption]="col.alias"
					[groupIndex]="col.groupIdx"
					[fixed]="false"
					[allowResizing]="true"
					[allowHiding]="true"
					[allowGrouping]="true"
					[visible]="col.visible"
					[allowSearch]="col.isSearchable"
					[allowFiltering]="col.isFilterable"
					[allowSorting]="true"
					[format]="col.format"
					[alignment]="'left'"
					[visibleIndex]="col.visibleIndex"
					[allowHeaderFiltering]="col.hasHeaderFilter"
					[width]="'7%'"
					cellTemplate="typeTemplate"></dxi-column>

				<dxi-column
					*ngIf="col.field === 'description'"
					[dataField]="col.field"
					[caption]="col.alias"
					[groupIndex]="col.groupIdx"
					[fixed]="false"
					[allowResizing]="true"
					[allowHiding]="true"
					[allowGrouping]="true"
					[visible]="col.visible"
					[allowSearch]="col.isSearchable"
					[allowFiltering]="col.isFilterable"
					[allowSorting]="true"
					[format]="col.format"
					[alignment]="'left'"
					[visibleIndex]="col.visibleIndex"
					[allowHeaderFiltering]="col.hasHeaderFilter"></dxi-column>

				<dxi-column
					*ngIf="col.field === 'isPrimaryKey'"
					[dataField]="col.field"
					[caption]="col.alias"
					[groupIndex]="col.groupIdx"
					[fixed]="false"
					[allowResizing]="true"
					[allowHiding]="true"
					[allowGrouping]="true"
					[visible]="col.visible"
					[allowSearch]="col.isSearchable"
					[allowFiltering]="col.isFilterable"
					[allowSorting]="true"
					[format]="col.format"
					[alignment]="'left'"
					[visibleIndex]="col.visibleIndex"
					[allowHeaderFiltering]="col.hasHeaderFilter"
					[width]="'15%'"
					cellTemplate="isPrimaryKeyTemplate"></dxi-column>

				<dxi-column
					*ngIf="col.field === 'isFilterable'"
					[dataField]="col.field"
					[caption]="col.alias"
					[groupIndex]="col.groupIdx"
					[fixed]="false"
					[allowResizing]="true"
					[allowHiding]="true"
					[allowGrouping]="true"
					[visible]="col.visible"
					[allowSearch]="col.isSearchable"
					[allowFiltering]="col.isFilterable"
					[allowSorting]="true"
					[format]="col.format"
					[alignment]="'left'"
					[visibleIndex]="col.visibleIndex"
					[allowHeaderFiltering]="col.hasHeaderFilter"
					[width]="'15%'"
					cellTemplate="isFilterableTemplate"></dxi-column>
			</ng-container>

			<div
				*dxTemplate="let cell of 'labelTemplate'"
				class="label-column-template">
				<span
					[matTooltip]="cell.data['exposedLabel']"
					[matTooltipDisabled]="cell.data['exposedLabel'].length < 24"
					matTooltipPosition="left"
					class="label-column-template__label">
					{{ cell.data['exposedLabel'] | truncate: 24 }}
				</span>
			</div>

			<div *dxTemplate="let cell of 'typeTemplate'" class="type-template">
				<app-column-type
					[type]="cell.data['type']"
					[isList]="cell.data['isList']"></app-column-type>
			</div>

			<div *dxTemplate="let cell of 'isFilterableTemplate'">
				<mat-icon
					*ngIf="cell.data['isFilterable']"
					class="filterable-column"
					[svgIcon]="DcIcons.Check"></mat-icon>
			</div>

			<div *dxTemplate="let cell of 'isPrimaryKeyTemplate'">
				<mat-icon
					*ngIf="cell.data['isPrimaryKey']"
					class="primary-key-column"
					[svgIcon]="DcIcons.Check"></mat-icon>
			</div>
		</dx-data-grid>
	</ng-template>
</ng-container>
